.walletmenu {
    .wallet-box {
        padding: 17px 18px;
        width: 410px;
        height: 100vh;
        background: var(--white);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

    }

    .close-icon {
        width: 17px;
        height: 17px;
        position: absolute;
        right: 18px;
        top: 17px;
        cursor: pointer;
    }

    .wallet-heading {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: var(--black);
        margin-top: 8px;
    }

    .user-detail {
        display: flex;
        padding: 20px 23px;
        align-items: center;
        justify-content: center;
    }

    .user-id {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: var(--textlight);
        display: flex;
        align-items: flex-end;
    }

    .copy {
        margin-left: 13.33px;
        cursor: pointer;
    }

    .wallet-details {
        display: flex;
        justify-content: center;
    }

    .inner-box {
        width: 281px;
        height: 120px;
        border: 1px solid var(--border);
        border-radius: 4px;
        overflow: hidden;
    }

    .wallet-info {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .lighttext {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: var(--textlight);
    }

    .darktext {
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: var(--textdark);
        margin-bottom: 15px;
    }

    @media screen and (max-width:576px) {
        .wallet-box {
            width: 352px;
        }
    }

}