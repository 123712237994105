.SearchResult {

    .image {
        background-color: var(--bgwhite);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        min-width: 40px;
        overflow: hidden;

        .img {
            width: 100%;
            height: 100%;
        }
    }

    .result {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.9px;
        color: var(--textlight);
        white-space: nowrap;
        padding: 11.45px 0px;
        cursor: pointer;
    }

    .result:hover {
        background: var(--extralight);
        color: var(--textdark);
        border-radius: 5px;
    }

    .result-text {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    hr {
        margin: 0;
    }

    .project-namee {
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
    }

    .dropdown-item:focus{
        outline: none;
    }
}