.TableSearch {
    .searchbox {
      cursor: text;
      display: flex;
      align-items: center;
      box-shadow: 1.44px 1.44px 7.22px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      line-height: 26px;
      padding: 8.63px 19.63px;
      //width: 100%;
      width: 378px;
      margin: auto;
      height: 34px;
      justify-content: space-between;
    }
  
    .innerinputfield {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--inputdark);
      border: none;
      background: none;
      margin-left: 10px;
      width: 100%;
    }
  
    .searchIcon {
      margin-left: 10px;
    }
  
    input:focus,
    textarea:focus,
    select:focus {
      outline: none;
    }
  
    .searchinside {
      
      .closeicon {
        height: 16px;
        width: 16px;
      }
  
      .pointer {
        cursor: pointer;
        margin-right: 10px;
        -webkit-box-pack: center;
        justify-content: center;
      }
    }
  
    .searchinside {
      path {
        stroke: var(--inputdark);
      }
  
      .closeicon {
        path {
          stroke: var(--white);
          fill: var(--inputdark);
        }
      }
    }
  
    ::placeholder {
      color: var(--inputdark);
      /* Firefox */
    }
  
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--inputdark);
    }
  
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--inputdark);
    }
  
    
  .filter{
    display: flex;
    align-items: center;
  }
  }