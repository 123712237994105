@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {


  //NEW COLOR VARAIBLE
  --primary: #9D20FF;
  --border: #E5E8EB;
  --textdark: #261B1B;
  --textlight: #8A8181;
  --inputdark: #8F8F8F;
  --inputlight: #F5F5F5;
  --light: #F8F8F8;
  --errortext: #dc3545;
  --underline: #1879BD;
  --extralight: #F3F3F3;
  --borderred:#2AC3D1;
  --white: #FFFFFF;
  --colortext: #9747FF;
  --textdarker: #1D1D1D;
  --black: #000000;
  --lightgrey: #606060;
  --inputgreen: #4edf0a;
  --inputred: #e75265;
  --primarydark: #006dbb;
  --textred: #2AC3D1;
  --inputbg: rgba(247, 249, 253, 0.75);
  --bluebg: rgba(142, 194, 254, 0.8);
  --pagetext: #303030D6;
  --pageborder: #30303024;
  --bgblue: #5D9DF6;
  --tablebg: #F3F3F380;
  --yellowtag: #2AC3D1;
  --btntext: #2AC3D1;
  --sliderarrow: #3C3939;
  --loaderblock1: #2AC3D1;
  --loaderblock2:  #3F51DC;
  --tagbg: #261b1b80;
  --textred2: #0d6efd;
  --linktext:#9747FF;
  --disabled:#e5e5e5;
  --disablebtntext: #aaaaaa;
}

html {
  height: auto;
  width: 100%;
}

body,
#root {
  margin: 0;
  padding: 0 !important;
  background-color: var(--white);
  font-family: 'Inter', sans-serif;
  width: 100%;
  height: auto;
  text-align: center;
  box-sizing: border-box;
  color: var(--textdark);

   // Container Width
   .container{
    width: 95%;
   }
   @media  (min-width:576px) {
    .container{
      max-width: 95%;
    }
   }

   @media  (min-width:768px) {
    .container{
      max-width: 95%;
    }
   }

   @media  (min-width:992px) {
    .container{
      max-width: 95%;
    }
  }
  @media  (min-width:1200px) {
    .container{
      max-width: 95%;
    }
  }
  @media  (min-width:1400px) {
    .container{
      max-width: 95%;
    }
  }
}

.form-control:focus {
  color: unset;
  background-color: unset;
  border-color: unset;
  outline: none;
  box-shadow: none;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--borderdark);
}

.App {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.cm {
  color: var(--textdark);
}

.table {
  margin-bottom: 0px;
}

.closebtn {
  position: absolute;
  right: 12.83px;
  top: 13.83px;
  cursor: pointer;
  width: 23.33px;
  height: 23.33px;
}

// pagination start
.paginationallignment {
  display: inline-block;
  // float: right;
}

.pagination {
  margin-bottom: 0px;
}

.pagination>li {
  display: inline;
  margin-left: 6px;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 8px 10px;
  line-height: 1.42857143;
  text-decoration: none;
  margin-left: -1px;
  font-size: 16px;
  font-weight: 600;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
  border: 1px solid var(--pageborder);
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--textdarker);
  font-weight: 900;
  margin-right: 50px;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  svg {
    path {
      fill: var(--lightgrey) !important;
    }
  }
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border: 1px solid var(--pageborder);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 800;
  color: var(--textdark);
  margin-left: 50px;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  svg {
    path {
      fill: var(--lightgrey) !important;
    }
  }
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  z-index: 3;
  background: var(--white);
  //border: 1px solid var(--pageborder);
  box-sizing: border-box;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
  border: 1px solid var(--pageborder);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: not-allowed;
}

.pagination-lg>li>a,
.pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.3333333;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.pagination-sm>li>a,
.pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.paginationicon {
  path {
    fill: var(--textdark);
  }
}

// pagination end

.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  color: unset;
  background-color: unset;
  border-color: unset;
  box-shadow: unset;
  border: unset;
}

#button-tooltip {
  z-index: 1000;
}

.react-datepicker-ignore-onclickoutside {
  outline: none;
}

//react multiple carousel
.carousel-div {
  .react-multiple-carousel__arrow::before {
    color: var(--sliderarrow);
  }

  .react-multiple-carousel__arrow {
    background: var(--white);
    box-shadow: 0px 11px 12px rgba(0, 0, 0, 0.1);

  }

  .react-multiple-carousel__arrow--right {
    bottom: 14px;
    right: 29px;
    min-width: 50px;
    min-height: 50px;
  }

  .react-multiple-carousel__arrow--left {
    bottom: 18px;
    left: unset;
    right: 129px;
    min-height: 42px;
    min-width: 42px;
  }

  .react-multiple-carousel__arrow:hover{
    background: var(--white);
  }
}

#tooltip-btn{
  z-index: 1000;
}

/* ===== Scrollbar CSS ===== 
 
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: var(--white);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--border);
    border-radius: 10px;
    
  }*/

  @media screen and (max-width:480px) {
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
      top: 0px;
      transform: translateX(-95%);
  }
  }

 
