.CustomButton {
  .buttonmain {
    font-weight: 600;
    font-size: 15.2657px;
    line-height: 18px;
    width: 117.86px;
    height: 37.31px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 2.5px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    color: var(--white);
    background: linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%);
    &:disabled {
      cursor: not-allowed;
    }
    padding: 0px;
  }
  .buttonmain:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  }
  .buttonmain:disabled {
    background: var(--disabled);
    cursor: not-allowed;
    color: var(--disablebtntext);
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .cancelbtn {
    width: 117.86px;
    height: 37.31px;
    border-radius: 2.5px;
    font-weight: 600;
    font-size: 15.2657px;
    line-height: 18px;
    text-align: center;
    border: 1px solid transparent;
    background: linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, #2AC3D1, #3F51DC) border-box;
    color: var(--btntext);
  }
}
