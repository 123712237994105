.loader{
    #preloader {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // background: #ffffff;
        z-index: 99999;
        opacity: 0;
    }
    
    .twitch-loading {
        width: 70px;
        height: 70px;
        position: relative;
        transform: rotate(45deg);
        --center-square-delay: .08s;
        --center-square-ease: cubic-bezier(0, 0, 0.24, 1.31);
        --duration: 2s;
        --first-quarter-delay: .5s;
        --second-quarter-delay: 1s;
        --third-quarter-delay: 1.5s;
        --corner-square-ease: ease-out;
    }
    
        span {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: var(--loaderblock1);
            position: absolute;
            opacity: 0;
        }
    
    
    .center-square {
        background-color: var(--loaderblock2);
        top: 0;
        left: 0;
        animation: goAround var(--duration) var(--center-square-ease) var(--center-square-delay) infinite;
    }
    
    .msg{
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin-top: 35px;
        padding: 0px 12px;
    }
    
    @keyframes goAround {
        from,
        to {
            opacity: 1;
            top: 0;
            left: 0;
        }
    
        25% {
            top: 0;
            left: calc(100% - 25px);
        }
    
        50% {
            top: calc(100% - 25px);
            left: calc(100% - 25px);
        }
    
        75% {
            top: calc(100% - 25px);
            left: 0;
        }
    }
    
    .top-left-square {
        top: 0;
        left: 0;
        animation:
            topLeftKick var(--duration) var(--center-square-ease) infinite forwards;
    }
    
    @keyframes topLeftKick {
        from,
        15% {
            left: -50px;
            opacity: 1;
        }
    
        15%,
        100% {
            left: 0;
        }
    
        to {
            opacity: 0;
        }
    }
    
    .top-right-square {
        top: 0;
        left: calc(100% - 25px);
        animation:
            topRightKick var(--duration) var(--center-square-ease) var(--first-quarter-delay) infinite forwards;
    }
    
    @keyframes topRightKick {
    
        from,
        15% {
            top: -50px;
            opacity: 1;
        }
    
        15%,
        100% {
            top: 0;
        }
    
        to {
            opacity: 0;
        }
    
    }
    
    .bottom-right-square{
        top: calc(100% - 25px);
        left: 100%;
        animation:
            bottomRightKick var(--duration) var(--center-square-ease) var(--second-quarter-delay) infinite forwards;
    }
    
    @keyframes bottomRightKick {
        from,
        15% {
            left: calc(100% + 25px);
            opacity: 1;
        }
    
        15%,
        100% {
            left: calc(100% - 25px);
        }
    
        to {
            opacity: 0;
        }
    }
    
    .bottom-left-square {
        top: calc(100% - 25px);
        left: 0;
        animation:
            bottomLeftKick var(--duration) var(--center-square-ease) var(--third-quarter-delay) infinite forwards;
    }
    
    @keyframes bottomLeftKick {
        from,
        15% {
            top: calc(100% + 25px);
            opacity: 1;
        }
    
        15%,
        100% {
            top: calc(100% - 25px);
        }
    
        to {
            opacity: 0;
        }
    
    }
    
    .loader--hide {
        display: none;
    }
    
    .fadeloader {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 99999;
    
        &::after {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: var(--white);
            opacity: 0;
        }
    }
    
    .fadeinloader::after {
        animation: fadeloaderin .3s ease-out forwards;
    }
    
    @keyframes fadeloaderin {
        from {
            opacity: 0;
        }
    
        to {
            opacity: 0.8;
        }
    }
    
    .fadeoutloader::after {
        animation: fadeloaderout .5s ease-in forwards .5s;
        // display: none;
    }
    
    @keyframes fadeloaderout {
        from {
            opacity: 0.8;
        }
    
        to {
            opacity: 0;
            // display: none;
        }
    }
    
    .fadeinpreloader {
        opacity: 0;
        animation: fadepreloaderin .5s ease-out forwards .3s;
    }
    
    @keyframes fadepreloaderin {
        from {
            opacity: 0;
        }
    
        to {
            opacity: 1;
        }
    }
    
    .fadeoutpreloader {
        opacity: 1;
        animation: fadepreloaderout .4s ease-out forwards;
    }
    
    @keyframes fadepreloaderout {
        from {
            opacity: 1;
        }
    
        to {
            opacity: 0;
        }
    }
    }