.TemplatePopup {
    .modal-body {
        padding: 60px 27.5px 42.7px 27.5px;
    }

    .modal-dialog {
        max-width: 335px;
    }

    .modal-content {
        box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
        border: none;
        border-radius: 4px;
    }

    .heading-text {
        font-size: 18px;
        font-weight: 400;
        color: var(--textlight);
        text-align: center;
        margin-bottom: 25px;
    }

    .btn-div {
        display: flex;
        justify-content: center;
    }
    .message-text{
        margin-bottom: 16px;
        text-align: center;
    }


    .submit-btn {
        width: 117.86px;
        height: 37.31px;
        border-radius: 2.5px;
        font-weight: 600;
        font-size: 15.2657px;
    }
    .submit-btn2 {
        width: 200.86px;
        height: 50.31px;
        border-radius: 2.5px;
        font-weight: 600;
        font-size: 15.2657px;
    }

    .connect-btn{
        width: 171px;
    }

    @media screen and (max-width:768px) {
        .modal-dialog {
            margin: 0 auto;
        }
    }
}