.DeletePopup {
  .modal-body {
    padding: 49px 27.5px 40.7px 27.5px;
  }

  .modal-dialog {
    max-width: 335px;
  }

  .modal-content {
    box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
    border: none;
    border-radius: 4px;
  }

  .heading-text {
    font-size: 18px;
    font-weight: 400;
    color: var(--textlight);
    text-align: center;
    margin-bottom: 40px;
  }

  .admin-btn-div {
    display: flex;
    justify-content: center;


    .confirm {
      .buttonmain {
        width: 117.86px;
        height: 37.31px;
        background: linear-gradient(68.47deg, #8EC2FE 21.82%, #8383EA 95.7%);
        border-radius: 2.5px;
        margin-right: 15px;
      }
    }

    .cancel {
      .buttonmain:hover {
        box-shadow: none;
      }
      .cancelbtn:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
      }
    }
  }

  .btn-div {
    display: flex;
    justify-content: center;


    .confirm {
      .buttonmain {
        margin-right: 15px;
      }
    }

    .cancel {
      .cancelbtn{
        background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
        color: var(--btntext);
      }
      .buttonmain:hover {
        box-shadow: none;
      }
      .cancelbtn:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
      }
    }
  }

  @media screen and (max-width:768px) {
    .modal-dialog {
        margin: 0 auto;
    } 
}

}