.BuyPowerVotePopup {
    .modal-body {
        padding: 38px 32px;
    }

    .modal-dialog {
        max-width: 572px;
    }

    .modal-content {
        box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
        border: none;
        border-radius: 5.43038px;
    }

    .heading-text {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: var(--textdark);
        text-align: center;
        margin-bottom: 26px;
    }


    .btn-div {
        display: flex;
        justify-content: center;

        .confirm {
            .buttonmain {
                margin-right: 15px;
            }
        }

        .cancel {
            .cancelbtn {
                background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
                color: var(--btntext);
            }

            .buttonmain:hover {
                box-shadow: none;
            }

            .cancelbtn:hover {
                box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
            }
        }
    }

    .buy-btn {
        margin-bottom: 40px;
        margin-top: 7px;

        .cancelbtn {
            width: 135px;
            background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
            color: var(--btntext);
        }

        .buttonmain:hover {
            box-shadow: none;
        }

        .cancelbtn:hover {
            box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
        }
    }

.detail-list{
    display: flex;
    flex-wrap: wrap;
}

    .vote-detail {
        width: 190px;
        margin: auto;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 38px;
    }

    .checkboxholder {
        label {
            font-weight: 600;
            font-size: 20px;
            color: var(--textdark);
            letter-spacing: 0.17px;
            margin-left: 7px;
        }
    }

    .form-check {
        margin-top: 12px;
        margin-bottom: 0;
    }

    .form-check-input:checked {
        background-color: var(--inputgreen);
        border: 2px solid var(--white);
        width: 20px;
        height: 20px;
    }

    .form-check-input {
        box-shadow: -5px 5px 10px rgb(0 0 0 / 10%);
        background-color: var(--extralight);
        border: 2px solid var(--white);
        width: 20px;
        height: 20px;
    }

    .form-check-input:checked[type="radio"] {
        background-image: none;
    }

    .show-msg{
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: var(--textdark);
        letter-spacing: 0.01em;
        margin-bottom: 20px;
    }


    @media screen and (max-width:768px) {
        .modal-dialog {
            margin: 0 auto;
        }

        .heading-text {
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 20px;
        }
    }

    .error-text {
        font-size: 14px;
        color: var(--errortext);
        text-align: center;
        margin-top: 15px;
      }

    @media screen and (max-width:576px) {


        .detail-text {
            font-size: 18px;
        }


        .modal-body {
            padding: 38px 5%;
        }

        .modal-dialog {
            margin: 0 10px;
        }

        .detail-list{
            flex-direction: column;
        }
    }
}