.VoteSuccessfulPopup {
    .modal-body {
        padding: 36px 0 33.69px 0;
    }

    .modal-dialog {
        max-width: 401.7px;
    }

    .modal-content {
        box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
        border: none;
        border-radius: 7.19457px;
    }

    .success {
        text-align: center;
    }

    .tick-icon {
        margin-bottom: 4px;
    }

    .bold-text {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: var(--textdark);
        margin-bottom: 3px;
    }

    .sub-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--textlight);
        margin-bottom: 33px;
    }
    .link-text {
        font-weight: 400;
    font-size: 16px;
    color: var(--underline);
    width: max-content;
    margin:-16px auto 16px;
    cursor: pointer;
    }

    .btn-div {
        display: flex;
        justify-content: center;


        .cancel {
            .cancelbtn{
              background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
              color: var(--btntext);
            }
            .buttonmain:hover {
              box-shadow: none;
            }
            .cancelbtn:hover {
              box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
            }
          }
    }


    @media screen and (max-width:768px) {
        .modal-dialog {
            margin: 0 auto;
        } 
    }

    @media  screen and (max-width:440px) {
        .modal-dialog{
            margin: 0 10px;
        }
    }
}