.FormPopup {
    .modal-body {
        padding: 45px 95px;
    }

    .modal-dialog {
        max-width: 517px;
    }

    .modal-content {
        background-color: var(--white);
        border-radius: 10px;
    }
     .error-text{
        font-size: 14px;
        color: var(--errortext);
    }
    .error-div{
        margin-top: 15px;
    }
    .visually-hidden{
        display: none;
    }
    .upload{
        cursor: pointer;
    }


    .headingtext {
        font-size: 20px;
        font-weight: 600;
        color: var(--textdark);
        margin-bottom: 31px;
        text-align: center;
        line-height: 24px;
    }

    .wallet {
        display: flex;
        justify-content: center;
        padding: 17px 0;
        align-items: center;
    }

    .wallet-name {
        font-weight: 400;
        font-size: 16px;
        color: var(--textdark);
        margin-left: 20px;
    }

    .titles {
        margin-top: 30px;
        width: 100%;
    }

    .titleText {
        font-size: 16px;
        font-weight: 600;
        color: var(--textdark);
    }

    .logo-imgappearance {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        cursor: pointer;
    }

    .outer-div {
        text-align: center;
    }

    .img-type {
        font-weight: 400;
        font-size: 14px;
        color: var(--textlight);
        margin-top: 21px;
    }

    .edit-icon {
        position: absolute;
        top: 20.5%;
        left: 54.5%;
        cursor: pointer;
    }

    .submit-btn {
        width: 117.86px;
        height: 37.31px;
        border-radius: 2.5px;
        font-weight: 600;
        font-size: 15.2657px;
    }

    .btn-div {
        text-align: center;
        margin-top: 30.5px;
    }

    @media screen and (max-width:768px) {
        .modal-dialog {
            margin: 0 auto;
        }
    }
    @media screen and (max-width:720px) {
        .modal-body {
            padding: 45px 5%;
        }

        .modal-dialog {
            width: calc(100% - 15px);
        }
    }
}