.AddWalletPopup {
    .modal-body {
        padding: 39px 28px;
    }

    .modal-dialog {
        max-width: 453px;
    }

    .modal-content {
        background-color: var(--white);
        border-radius: 10px;
    }

    .headingtext {
        font-size: 20px;
        font-weight: 600;
        color: var(--textdark);
        margin-bottom: 31px;
        text-align: center;
        line-height: 24px;
    }
    .wallet-div{
        border-radius: 11px;
        //background: var(--white);
        padding: 2px;
        font-weight: 400;
        font-size: 16px;
        color: var(--textdark);
        cursor: pointer;
    }
    .wallet-div:hover{
        background: linear-gradient(68.47deg, #8EC2FE 21.82%, #8383EA 95.7%);
        font-weight: 600;
    }

    .wallet {
        display: flex;
        padding: 17px 25%;
        align-items: center;
        border-radius: 10px;
        background: var(--white);
    }

    .logo{
        min-width: 34px;
    }

    .wallet-name {
        margin-left: 20px;
    }
    .error-text{
        font-size: 14px;
        color: var(--errortext);
        margin-top: 15px;
    }
    @media screen and (max-width:768px) {
        .modal-dialog {
            margin: 0 auto;
        }


        .wallet-div{
            max-width: 340px;
            margin: auto;
        }
    }

    @media screen and (max-width:576px) {
        .modal-dialog {
            width: calc(100% - 15px);
        }
        .wallet{
            padding: 17px 15%;
        }
    }
}