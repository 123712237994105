.CurrencyDropdown {
  .select-dp {
    .css-1pahdxg-control,
    .css-1s2u09g-control {
        height: 42px;
        background: var(--extralight);
        border: none;
        padding: 0 12px;
        box-shadow: none;
        width: 170px;
    }

    .css-6j8wv5-Input{
      margin: 0px;
    }

    .css-319lph-ValueContainer{
      padding: 2px 0px;
    }

    .css-1okebmr-indicatorSeparator {
        display: none;
    }

    .css-14el2xx-placeholder {
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--textlight);
        margin: 0px;
    }

    .css-qc6sy-singleValue {
        white-space: unset;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-word;
        margin: 0;
        text-align: left;
    }
    .css-26l3qy-menu{
      max-height: 100px;
      overflow-y: scroll;
  }
}
.currency-icon{
  width: 25px;
  height: 25px;
  margin-right: 12.37px;
}
}
