.CreatorDetailPageCard{

    .top-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;
    }

    .card-type {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: var(--textdark);
    }

    .see-all {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: right;
        color: var(--textlight);
        cursor: pointer;
    }

    .Card-Col{
        margin-bottom: 25px;
    }

    .card {
        border: none;
        box-shadow: 0px 11px 25px 9px rgba(0, 0, 0, 0.1);
        border-radius: 2.75863px;
        overflow: hidden;
        padding: 1px;
        height: 100%;
        min-height: 400px;
        cursor: pointer;
        position: relative;
        z-index: 1;
    }

    .card-img-block {
        width: 100%;
        height: 208px;
        border-radius: 2.76367px;
        overflow: hidden;
        cursor:pointer;

    }

    .card-body {
        position: relative;
        padding: 13px 16px;
        text-align: left;
    }

    .cover {
        width: 100%;
        height: 100%;
    }

    .left{
        width: calc(100% - 10px);
    }

    .project-name {
        text-align: left;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: var(--textdark);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-transform: capitalize;
        word-break: break-all;
    }

    .category-text {
        font-weight: 400;
        font-size: 17px;
        line-height: 21px;
        color: var(--textdark);
        margin: 6px 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-transform: capitalize;
        word-break: break-all;
    }

    .card-desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: var(--textlight);
    }

    .read {
        background: linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }

    .ama {
        position: absolute;
        height: 70px;
        width: 70px;
        top: 5px;
        left: 5px;
    }

    .last-line {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 15px;
        line-height: 26px;
        margin-top: 13px;
    }

    .color-text {
        color: var(--colortext);
    }

    .amount {
        color: var(--textdark);
    }

    .detail-div {
        display: flex;
        justify-content: space-between;
    }

    .vertical {
        height: 20.49px;
        width: 4.72px;
        cursor: pointer;
    }

    .threedot {
        background-color: transparent;
        padding: 0px;
        border: none;
        outline: none;
    }

    .threedot:hover{
        background-color: transparent;
    }

    .menu {
        border-radius: 4px;
        background-color: var(--white);
        border: 1px solid var(--border);
        min-width: max-content;
    }

    .threedot.dropdown-toggle::after {
        display: none;
    }

    .dropdown.show {
        display: inline-block;
    }

    .dropdown-item {
        font-size: 14px;
        font-weight: 400;
        color: var(--textlight);
        padding: 4px 23px;
    }

    .dropdown-item:focus,
    .dropdown-item:hover {
        text-decoration: none;
        cursor: pointer;
    }

    .dropdown-item:active,
    .dropdown-item:hover {
        background-color: var(--extralight);
    }

    .btn-check:active+.btn-primary:focus,
    .btn-check:checked+.btn-primary:focus,
    .btn-primary.active:focus,
    .btn-primary:active:focus,
    .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: none
    }

    button:focus:not(:focus-visible) {
        box-shadow: none;
    }

    btn-check:active+.btn-primary,
    .btn-check:checked+.btn-primary,
    .btn-primary.active,
    .btn-primary:active,
    .show>.btn-primary.dropdown-toggle {
        background-color: transparent;
        border: none;
    }

    .no-data {
        height: 250px;
        width: 100%;
    }
}