.ReportPopup {
    .modal-body {
      padding: 38px 52px;
    }
  
    .modal-dialog {
      max-width: 572px;
    }
  
    .modal-content {
      box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
      border: none;
      border-radius: 5.43038px;
    }
  
    .heading-text {
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      color: var(--textdark);
      text-align: center;
      margin-bottom: 36px;
    }
    .error-text{
      font-size: 14px;
      color: var(--errortext);
      text-align: center;
      margin-top: 15px;
  }
  
    .admin-btn-div {
      display: flex;
      justify-content: center;
  
  
      .confirm {
        .buttonmain {
          width: 117.86px;
          height: 37.31px;
          background: linear-gradient(68.47deg, #8EC2FE 21.82%, #8383EA 95.7%);
          border-radius: 2.5px;
          margin-right: 15px;
        }
      }
  
      .cancel {
        width: 117.86px;
        height: 38.9px;
        border-radius: 2.5px;
        background: linear-gradient(68.47deg, #8EC2FE 21.82%, #8383EA 95.7%);
        padding: 0.85px;
  
        .CustomButton {
          background: var(--white);
          border-radius: 2.5px;
        }
  
        .buttonmain {
          width: 117.86px;
          height: 37.31px;
          border-radius: 2.5px;
          font-weight: 600;
          font-size: 15.2657px;
          line-height: 18px;
          text-align: center;
          background: linear-gradient(68.47deg, #8EC2FE 21.82%, #8383EA 95.7%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .buttonmain:hover{
          box-shadow: none;
        }
      }
      .cancel:hover{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
    .second-heading{
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: 0.167869px;
      color: var(--textdark);
    }

    textarea.form-control {
      width: 100%;
      height: 118px;
      padding: 12px;
      box-sizing: border-box;
      outline: none;
      font-weight: 400;
      position: relative;
      font-size: 14px;
      line-height: 18px;
      mix-blend-mode: normal;
      background: var(--extralight);
      color: var(--textdark);
      border: none;
      border-radius: 4px;
      resize: none;
  }

  textarea.form-control:focus {
      border-color: var(--border);
      box-shadow: none;
      margin: none;
  }
  
    .btn-div {
      display: flex;
      justify-content: center;
  
  
      .confirm {
        .buttonmain {
          margin-right: 15px;
        }
      }

      .detail-div {
        margin-bottom: 62px;
       
    }
   
    .cancel {
      .cancelbtn{
        background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
        color: var(--btntext);
      }
      .buttonmain:hover {
        box-shadow: none;
      }
      .cancelbtn:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
      }
    }
    }
    .input-div {
        display: flex;
        margin-bottom: 64px;
        justify-content: space-between;
        flex-wrap: wrap;
    }
   
    .inputbox{
        input{
          border: none;
          background: var(--extralight);
          width: 212px;
          height: 42px;
          text-align: center;
          margin: 10px 0px;
        }
        ::placeholder{
            text-align: center;
        }

        .InputField .input.form-control.input{
            padding: 0 13px;
        }
    }
    @media screen and (max-width:592px) {
      .modal-dialog {
        width:calc(100% - 20px)
      }

      .modal-body{
        padding: 38px 5%;
      }
    }
  }