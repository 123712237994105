.InputField {
    height: 100%;
    position: relative;
    .area {
      height: 100%;
    }
    .input.form-control.input:focus {
      border-color: none;
      box-shadow: none;
      margin: none;
    }
    .input.form-control.bottomborder {
      border-bottom: 1px solid var(--borderdark);
      border-top: none;
      border-left: none;
      border-right: none;
    }
    .input.form-control.input {
      width: 100%;
      height: 42px;
      padding: 0 12px;
      box-sizing: border-box;
      outline: none;
      font-weight: 400;
      position: relative;
      font-size: 14px;
      line-height: 18px;
      mix-blend-mode: normal;
      background: var(--extralight);
      color: var(--textdark);
      border: none;
      border-radius: 4px;
    }
    .input.form-control.roundedborderfield {
      border: 1px solid var(--border);
      padding: 10px;
      height: 40px;
      border-radius: 2px;
    }
    .textarea.form-control {
      width: 100%;
      height: 50px;
      padding: 12px;
      border-radius: 0px;
      box-sizing: border-box;
      outline: none;
      font-weight: 400;
      position: relative;
      font-size: 18px;
      line-height: 18px;
      mix-blend-mode: normal;
      background: var(--white);
      color: var(--textdark);
      border-color: var(--border);
      border-radius: 10px;
  }
  .passwordIcon {
    position: absolute;
    right: 20px;
    top: 10.5px;
    width: 20px;
    cursor: pointer;
  }
    .othersIcon {
      position: absolute;
      top: 50%;
      right: 6%;
      transform: translateY(-50%);
      width: 20px;
      z-index: 1;
      cursor: pointer;
    }
    .linktxt {
      position: absolute;
      top: 55%;
      transform: translate(0, -50%);
      right: 5px;
      cursor: pointer;
      font-size: 14px;
      text-decoration: none;
      color: var(--primary);
  
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      background: none;
      border: none;
    }
    .form-control.input.inputwoanyborder {
      border: none;
      font-size: 14px;
      height: 37px;
      font-weight: 600;
    }
  
    .input.form-control.withoutleftborder {
      width: 100%;
      height: 100%;
      padding: 0 10px;
      border-radius: 4px;
      box-sizing: border-box;
      outline: none;
  
      font-weight: 500;
      position: relative;
      font-size: 12px;
      line-height: 18px;
      mix-blend-mode: normal;
      border: none;
      background: var(--white);
      color: var(--textdark);
    }
    .input[type="date"]::-webkit-calendar-picker-indicator {
      opacity: 0;
      position: absolute;
      z-index: 2;
      display: block;
      width: 40px;
      height: 20px;
      margin-left: 15px;
      right: 0px;
      border-width: thin;
      cursor: pointer;
    }
  
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    
    ::placeholder{
      color: var(--textlight);
  }
  }
  