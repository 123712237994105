#AdminVotes {
    padding-top: 89px;
    padding-bottom: 27px;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 54px;
    }

    .amount-div {
        display: flex;
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.06em;

        .value {
            margin-left: 12px;
        }
    }

    tr {
        vertical-align: middle;
        height: 54px;
    }

    td,
    th {
        border: none;
    }

    .error-text {
        font-size: 14px;
        color: var(--errortext);
    }

    .btmmargin {
        display: inline-block;
        margin-bottom: 20px;
    }


    .trtbleheading {
        font-weight: 600;
        font-size: 19.7959px;
        line-height: 24px;
        color: var(--white);
        vertical-align: middle;
        height: 54px;
        white-space: nowrap;
        text-align: left;
    }

    .mr-0 {
        margin-right: 0px;
    }
    .no-data{
        height: 250px;
        width: 100%;
    }


    .amt {
        font-weight: 500;
        font-size: 17.5964px;
        vertical-align: middle;
        line-height: 22px;
        margin-top: -2px;
        color: var(--textdark);
        text-transform: capitalize;
    }

    .ama {
        white-space: nowrap;
    }


    .amt {
        padding: 0;
    }

    .address-div{
        width: max-content;
        cursor: pointer;
    }

    .innerTd {
        padding: 9px;
        border-radius: 0.1px;
        border-left: none;
        border-right: none;
        height: 38px;
        text-align: left;
        display: flex;
        align-items: unset;
        justify-content: center;
        flex-direction: column;
    }

    .vote-quantity{
        text-align: center;
    }


    thead {
        background: linear-gradient(68.47deg, #8EC2FE 21.82%, #8383EA 95.7%);
        height: 54px;
        vertical-align: middle;
        color: var(--white);
    }

    thead tr th:first-child {
        border-top-left-radius: 4.39909px;
        padding: 0 32.8px;
        text-align: center;
    }

    thead tr th:last-child {
        border-top-right-radius: 4.39909px;
    }

    tbody tr:last-child td:first-child {
        border-bottom-left-radius: 4.39909px;
    }

    tbody tr:last-child td:last-child {
        border-bottom-right-radius: 4.39909px;
    }

    .table-responsive-sm {
        overflow-y: visible;
        overflow-x: auto;
        width: 100%;
        box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 4%);
        border-radius: 4px;
        padding-bottom: 10px;
    }


    .id,
    .dropdown {
        text-align: center;
    }

    .tbl-btn {
        width: 117.86px;
        height: 37.31px;
        background: linear-gradient(68.47deg, #8EC2FE 21.82%, #8383EA 95.7%);
        border-radius: 2.5px;
        font-weight: 600;
        font-size: 15.2657px;
    }

    .btn-primary:focus {
        box-shadow: none;
    }

    tr:nth-child(even) {
        background-color: var(--tablebg);
    }

    .click {
        cursor: pointer;
    }



    .category {
        min-width: 225px;
    }

    .paginationsection {
        display: flex;
        justify-content: space-between;
        margin-top: 30.17px;
        align-items: center;
    }

    .noofpagetxt {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--pagetext);
    }

    .no-data {
        height: 250px;
        width: 100%;
    }


    @media screen and (max-width:991px) {
        .amt {
            font-size: 14px;
            line-height: 18px;
        }

        .TableSearch {
            margin-right: 40px;

            .searchbox {
                width: 100%;
            }
        }
    }

    @media screen and (max-width:768px) {
        .top {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 25px;
        }

        .TableSearch {
            margin-bottom: 20px;
            margin-right: 0px;
        }

        .trtbleheading{
            font-size: 18px;
        }
    }

    @media screen and (max-width: 576px){
        .pagination > li:first-child > a, .pagination > li:first-child > span{
            margin-right: 20px;
        }

        .pagination > li:last-child > a, .pagination > li:last-child > span{
            margin-left: 20px;
        }

        .paginationsection{
            flex-direction: column;
        }

        .paginationtxtholer{
            margin-bottom: 10px;
        }

        thead tr th:first-child{
            padding: 0 15px;
        }
    }

    @media screen and (max-width:440px) {
        .pagination > li:first-child > a, .pagination > li:first-child > span{
            margin-right: 10px;
        }

        .pagination > li:last-child > a, .pagination > li:last-child > span{
            margin-left: 10px;
        }

        .noofpagetxt{
            font-size: 14px;
            line-height: 18px;
        }
    }
}