.PowerVotePopup {
    .modal-body {
        padding: 38px 32px;
    }

    .modal-dialog {
        max-width: 572px;
    }

    .modal-content {
        box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
        border: none;
        border-radius: 5.43038px;
    }

    .heading-text {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: var(--textdark);
        text-align: center;
        margin-bottom: 61px;
    }

    .detail-list {
        text-align: center;
    }

    .detail-text {
        font-weight: 600;
        font-size: 20.9836px;
        line-height: 27px;
        color: var(--textdark);
        margin-bottom: 8px;
        font-family: 'Inter';
    }

    .inputs {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 10px;
        flex-wrap: wrap;

        .InputField .input.form-control.input {
            width: 122px;
        }

        ::placeholder {
            text-align: center;
        }

        .CurrencyDropdown .dropdown {
            width: 170px;
        }
    }
     .hideMe{
        display: none;
     }

    .count {
        font-weight: 400;
        font-size: 14px;
        line-height: 42px;
        color: var(--textlight);
        margin: 0 10px;
    }

    .vote-count{
        font-weight: 400;
        font-size: 14px;
        line-height: 42px;
        color: var(--textlight);
        margin: 0 10px;
        white-space: nowrap;
    }

    .available-amount {
        margin-bottom: 11px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--textlight);
    }

    .btn-div {
        display: flex;
        justify-content: center;
        margin-top: 28px;

        .confirm {
            .buttonmain {
                margin-right: 15px;
            }
        }

        .cancel {
            .cancelbtn {
                background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
                color: var(--btntext);
            }

            .buttonmain:hover {
                box-shadow: none;
            }

            .cancelbtn:hover {
                box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
            }
        }
    }

    .select-dp {
        .css-1pahdxg-control,
        .css-1s2u09g-control {
            height: 42px;
            background: var(--extralight);
            border: none;
            padding: 0 12px;
            box-shadow: none;
            width: 170px;
        }
    
        .css-6j8wv5-Input{
          margin: 0px;
        }
    
        .css-319lph-ValueContainer{
          padding: 2px 0px;
        }
    
        .css-1okebmr-indicatorSeparator {
            display: none;
        }
    
        .css-14el2xx-placeholder {
            text-align: left;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--textlight);
            margin: 0px;
        }
    
        .css-qc6sy-singleValue {
            white-space: unset;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-word;
            margin: 0;
            text-align: left;
        }
        .css-26l3qy-menu{
            max-height: 100px;
            overflow-y: scroll;
        }
    }

    .vote-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }

    .error-text {
        font-size: 14px;
        color: var(--errortext);
        text-align: center;
        margin-top: 15px;
    }

    @media screen and (max-width:768px) {
        .modal-dialog {
            margin: 0 auto;
        }

        .heading-text {
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 30px;
        }

        .vote-detail {
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width:576px) {
        .vote-detail {
            flex-direction: column;
            margin-bottom: 0px;
        }

        .detail-text {
            font-size: 18px;
        }

        .vote-count{
            margin-top: 10px;
        }

        .inputs {
            margin-top: 15px;
            flex-direction: column;
        }

        .modal-body {
            padding: 38px 5%;
        }

        .modal-dialog {
            margin: 0 10px;
        }

        .btn-div{
            margin-top: 0px;
        }
    }
}