.ConfirmVotePopup {
    .modal-body {
        padding: 58px 20px 42.7px 20px;
    }

    .modal-dialog {
        max-width: 572px;
    }

    .modal-content {
        box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
        border: none;
        border-radius: 6px;
    }

    .error-text {
        font-size: 14px;
        color: var(--errortext);
        text-align: center;
        margin-top: 15px;
    }

    .heading-text {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: var(--textdarker);
        text-align: center;
        margin-bottom: 37px;
    }

    .detail-list {
        display: flex;
        justify-content: center;
        width: 65%;
        margin: auto;
        text-align: left;

        ::marker {
            font-size: 20px;
        }
    }

    .title {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: var(--textlight);
        margin-bottom: -2px;
    }

    .name-text {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: var(--textdark);
        margin-bottom: 10px;
        word-break: break-word;
        text-transform: capitalize;
    }

    ul {
        margin-bottom: 44px;
    }

    .btn-div {
        display: flex;
        justify-content: center;


        .confirm {
            .buttonmain {
                margin-right: 15px;
            }
        }

        .cancel {
            .cancelbtn{
              background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
              color: var(--btntext);
            }
            .buttonmain:hover {
              box-shadow: none;
            }
            .cancelbtn:hover {
              box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
            }
          }
    }

    @media screen and (max-width:768px) {

        .modal-dialog {
            margin: 0 auto;
        } 
        
        .heading-text{
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 20px;
        }

        .detail-list{
            width: 100%;
        }
    }


    @media  screen and (max-width: 592px) {
        .modal-dialog{
            margin: 0 10px;
        }
    }

}