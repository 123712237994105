.ProjectDetailsScreen {
    padding-top: 80px;
    padding-bottom: 30px;

    .top-section {
        padding-bottom: 25px;
    }

    .details {
        text-align: left;
        margin-bottom: 30px;
    }

    .detail-div {
        display: flex;
        width: 100%;
    }

    .left {
        display: flex;
        justify-content: space-between;
    }

    .threedot {
        background-color: transparent;
        padding: 0px;
        border: none;
        outline: none;
    }

    .threedot:hover{
        background-color: transparent;
    }

    .menu {
        border-radius: 4px;
        background-color: var(--white);
        border: 1px solid var(--border);
        min-width: 118px;
    }

    .threedot.dropdown-toggle::after {
        display: none;
    }

    .dropdown.show {
        display: inline-block;
    }

    .dropdown-item {
        font-size: 14px;
        font-weight: 400;
        color: var(--textlight);
        padding-left: 23px;
    }

    .error-text {
        font-size: 14px;
        color: var(--errortext);
        margin-top: 15px;
    }

    .dropdown-item:focus,
    .dropdown-item:hover {
        text-decoration: none;
        cursor: pointer;
    }

    .dropdown-item:active,
    .dropdown-item:hover {
        background-color: var(--extralight);
    }

    .btn-check:active+.btn-primary:focus,
    .btn-check:checked+.btn-primary:focus,
    .btn-primary.active:focus,
    .btn-primary:active:focus,
    .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: none
    }

    button:focus:not(:focus-visible) {
        box-shadow: none;
    }

    .btn-check:active+.btn-primary,
    .btn-check:checked+.btn-primary,
    .btn-primary.active,
    .btn-primary:active,
    .show>.btn-primary.dropdown-toggle {
        background-color: transparent;
        border: none;
    }

    .vertical {
        width: 4.4px;
        cursor: pointer;
    }

    .title-text {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: var(--textlight);
    }

    .name {
        margin-top: 6px;
        font-weight: 600;
        font-size: 41px;
        line-height: 50px;
        color: var(--textdark);
        text-transform: capitalize;
        word-break: break-word;
    }


    .d-title {
        font-weight: 400;
        font-size: 15.371px;
        line-height: 19px;
        color: var(--textlight);
        text-align: left;
    }

    .d-text {
        margin-top: 9.18px;
        font-weight: 600;
        font-size: 20.4947px;
        line-height: 25px;
        color: var(--textdark);
        text-align: left;
    }
    .d-text-youtube {
        margin-top: 9.18px;
        font-weight: 600;
        font-size: 20.4947px;
        line-height: 25px;
        text-align: left;
        font-weight: 400;
        color: var(--underline);
       
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        cursor: pointer;
    }

    .videoIframe {
        border: none;
        width: 100%;
        height: 285px;
        border-radius: 4px;
        margin-bottom: 14px;
    }

    .file {
        display: flex;
    }

    .file-text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
        text-decoration: none;
        color: var(--textdark);
        font-weight: 600;
        font-size: 19.2137px;
    }

    .file-icon {
        margin-right: 9.75px;
        min-width: 11.25px;
        height: 16.57px;
        margin-top: 4px;
    }

    .iframe-div {
        padding: 0 30px;
    }

    .desc-div {
        text-align: left;
    }

    .desc-title {
        font-weight: 600;
        font-size: 15.37px;
        line-height: 19px;
        color: var(--textlight);
        margin-bottom: 9px;
    }

    .desc {
        font-weight: 500;
        font-size: 20.49px;
        line-height: 25px;
        color: var(--black);
        margin-bottom: 55px;
    }

    .btn-div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;


            .buttonmain {
                margin: 0 7.5px;
            }

        .cancel {
            .cancelbtn {
                background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
                color: var(--btntext);
            }

            .cancelbtn:disabled{
                background: var(--disabled);
                color: var(--disablebtntext);
            }

            .buttonmain:hover {
                box-shadow: none;
            }

            .cancelbtn:hover {
                box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
            }
        }
    }

    .back-icon {
        margin-right: 22px;
        cursor: pointer;
        width: 11.02px;
        height: 22.04px;
    }

    .details-screen {
        display: flex;
    }

    .contents {
        width: 100%;
    }

    .below-video {

        .comp-tag {
            float: left;
            width: 115px;
            height: 25px;
            background: var(--tagbg);
            font-weight: 600;
            font-size: 12px;
            line-height: 25px;
            color: var(--white);
            border-radius: 3px;
        }

        .go-to {
            float: right;

            a {
                color: var(--underline);
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
            }
        }
    }

    .details-section{
        width: 100%;
    }

    .sub-detail{
        margin-bottom: 20px;
    }

    

    @media screen and (max-width:992px) {
        .back {
            display: none;
        }

        .iframe-div {
            margin-top: 40px;
        }
    }

    @media screen and (max-width:768px) {
        .d-text {
            font-size: 18px;
            margin-top: 6px;
        }

        .iframe-div {
            margin-top: 30px;
        }

        .name{
            font-size: 36px;
            line-height: 40px;
        }
    }

    @media screen and (max-width:600px){
        .btn-div{
            .buttonmain {
                margin-bottom: 15px;
            }
        }
    }

    @media screen and (max-width:576px) {
        .iframe-div {
            padding: 0px;
        }

        .name {
            font-size: 32px;
            line-height: 38px;
        }

        .sub-detail {
            margin-bottom: 10px;
        }

        .desc-title {
            margin-bottom: 6px;
        }

        .desc {
            font-size: 18px;
            margin-bottom: 50px;
        }

        .sub-div{
            flex-direction: column;
        }
    }

    @media screen and (max-width:440px) {
        
    }
}