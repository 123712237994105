.searcharea {
  .searchbox {
    cursor: text;
    display: flex;
    align-items: center;
    box-shadow: 1.44px 1.44px 7.22px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    line-height: 26px;
    padding: 8.63px 19.63px;
    width: 100%;
    max-width: 378px;
    margin: auto;
    height: 34px;
    justify-content: space-between;
  }

  .innerinputfield {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--inputdark);
    border: none;
    background: none;
    margin-left: 10px;
    width: 100%;
  }

  .searchIcon {
    margin-left: 10px;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }

  .searchinside {
    
    .closeicon {
      height: 16px;
      width: 16px;
    }

    .pointer {
      cursor: pointer;
      margin-right: 10px;
      -webkit-box-pack: center;
      justify-content: center;
    }
  }

  .searchinside {
    path {
      stroke: var(--inputdark);
    }

    .closeicon {
      path {
        stroke: var(--white);
        fill: var(--inputdark);
      }
    }
  }

  ::placeholder {
    color: var(--inputdark);
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--inputdark);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--inputdark);
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    text-decoration: none;
    cursor: pointer;
  }

  .dropdown-item:active,
  .dropdown-item:hover {
    background-color: var(--extralight);
  }

  .btn-check:active+.btn-primary:focus,
  .btn-check:checked+.btn-primary:focus,
  .btn-primary.active:focus,
  .btn-primary:active:focus,
  .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none
  }

  button:focus:not(:focus-visible) {
    box-shadow: none;
  }

  .btn-primary,
  .btn-primary:hover,
  .btn-check:active+.btn-primary,
  .btn-check:checked+.btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show>.btn-primary.dropdown-toggle {
    background-color: transparent;
    border: none;
  }

  .dropdown-menu.show {
    width: 623px;
    padding: 33px 42px 20.69px 42px;
    border: none;
    box-shadow: 0px 8.35519px 18.9891px -3.03825px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 500px;
    overflow: auto;
  }
.filter{
  width: 100%;
  .dropdown-menu.show{
   width: 100%;
   height: max-content;
   padding: 10px 20px;
   max-height: 400px;
  }
}

  .align-search{
    display: flex;
    align-items: center;
    width: 100%;
  }
}