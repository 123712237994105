.DonatePopup {
    .modal-body {
        padding: 38px 80px;
    }

    .modal-dialog {
        max-width: 572px;
    }

    .modal-content {
        box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
        border: none;
        border-radius: 5.43038px;
    }

    .detail {
        text-align: center;
    }

    .project-name {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: var(--textdark);
        margin-bottom: 10px;
        text-transform: capitalize;
    }

    .category-name {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.06em;
        color: var(--textdark);
        margin-bottom: 27.72px;
    }

    .amt {
        margin-bottom: 31px;
    }

    .amount {
        font-weight: 600;
        font-size: 20.9836px;
        line-height: 27px;
        color: var(--textdark);
    }

    .title {
        font-weight: 400;
        font-size: 15.7377px;
        line-height: 27px;
        color: var(--colortext);
    }

    .inputs {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 21px;
        flex-wrap: wrap;

        .InputField {
            height: 42px;
            padding: 0 5px;
        }

        .InputField .input.form-control.input {
            width: 122px;
        }

        // ::placeholder {
        //     text-align: center;
        // }
    }

    .CurrencyDropdown {
        padding: 0 5px;
    }

    .count {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--textlight);
        word-break: break-all;
        padding: 12.5px 5px;
        text-transform: uppercase;
    }

    .desc {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--textlight);
        width: max-content;
        margin: auto;
        margin-bottom: 40px;
    }

    .read {
        color: var(--underline);
        cursor: pointer;
    }

    .btn-div {
        display: flex;
        justify-content: center;

        .confirm {
            .buttonmain {
                margin-right: 15px;
            }
        }

        .cancel {
            .cancelbtn {
                background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
                color: var(--btntext);
            }

            .buttonmain:hover {
                box-shadow: none;
            }

            .cancelbtn:hover {
                box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
            }
        }
    }

    .CurrencyDropdown .select-dp .css-1pahdxg-control,
    .CurrencyDropdown .select-dp .css-1s2u09g-control {
        width: 161px;
    }

    .vote-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;
    }

    .error-text {
        font-size: 14px;
        color: var(--errortext);
        margin-top: 15px;
    }

    @media screen and (max-width:768px) {
        .modal-dialog {
            margin: 0 auto;
        }

        .project-name {
            font-size: 28px;
            line-height: 32px;
        }
    }

    @media screen and (max-width: 592px) {
        .modal-dialog {
            margin: 0 10px;
        }
    }

    @media screen and (max-width: 576px) {
        .modal-body {
            padding: 38px 5%;
        }

        .inputs {
            flex-direction: column;

            .InputField {
                margin-bottom: 10px;

                .input.form-control.input {
                    width: 170px;
                }
            }
        }

        .CurrencyDropdown .select-dp .css-1pahdxg-control,
        .CurrencyDropdown .select-dp .css-1s2u09g-control {
            width: 170px;
        }
    }
}