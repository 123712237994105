.CampaignDeadlinePopup {
  .modal-body {
    padding: 63px 56px 53px 56px;
  }

  .modal-dialog {
    max-width: 580px;
  }

  .modal-content {
    box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
    border: none;
    border-radius: 4px;
  }

  .heading-text {
    font-weight: 600;
    font-size: 20px;
    color: var(--textdark);
    text-align: center;
    margin-bottom: 62px;
  }

  .error-text {
    font-size: 14px;
    color: var(--errortext);
    text-align: center;
    margin-top: 15px;
  }

  .admin-btn-div {
    display: flex;
    justify-content: center;


    .confirm {
      .buttonmain {
        margin-right: 15px;
      }
    }

    .cancel {
      
      .buttonmain:hover {
        box-shadow: none;
      }
      .cancelbtn:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
      }
    }
  }

  .btn-div {
    display: flex;
    justify-content: center;


    .confirm {
      .buttonmain {
        margin-right: 15px;
      }
    }

    .cancel {
      .cancelbtn{
        background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
        color: var(--btntext);
      }
      .buttonmain:hover {
        box-shadow: none;
      }
      .cancelbtn:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
      }
    }

    
  }

  .input-div {
    display: flex;
    margin-bottom: 62px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .amount {
    width: 100%;
  }

  .inputbox {
    input {
      border: none;
      background: var(--extralight);
      width: 200px;
      height: 42px;
      text-align: center;
      margin: 10px 0px;
      padding: 0 12px;
    }

    ::placeholder {
      text-align: center;
    }

    .InputField .input.form-control.input {
      padding: 0 13px;
    }
  }

  @media screen and (max-width:600px) {
    .modal-body {
      padding: 63px 5% 53px 5%;
  }

  .inputbox{
    width: 100%;

    input{
      width: 100%;
    }
  }

  .modal-dialog {
    margin: 0 10px;
  }
  }

}