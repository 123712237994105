.DeleteSuccessPopup {
    .modal-body {
        padding: 70px 27.5px 42.7px 27.5px;
    }

    .modal-dialog {
        max-width: 335px;
    }

    .modal-content {
        box-shadow: 0px 11px 25px 9px rgb(0 0 0 / 10%);
        border: none;
        border-radius: 4px;
    }

    .heading-text {
        font-size: 18px;
        font-weight: 400;
        color: var(--textlight);
        text-align: center;
        margin-bottom: 49px;
    }

    .btn-div {
        display: flex;
        justify-content: center;


        .cancel {
            .cancelbtn{
              background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
              color: var(--btntext);
            }
            .buttonmain:hover {
              box-shadow: none;
            }
            .cancelbtn:hover {
              box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
            }
          }
    }

    .admin-btn-div {
        display: flex;
        justify-content: center;


        .cancel {
            .buttonmain:hover {
              box-shadow: none;
            }
            .cancelbtn:hover {
              box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
            }
          }
    }


    @media screen and (max-width:768px) {
      .modal-dialog {
          margin: 0 auto;
      } 
  }

}