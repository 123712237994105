.CreatorDetailsPage {
  padding-top: 85px;
  padding-bottom: 30px;

  .creators-detail {
    display: flex;

    justify-content: space-between;
  }

  .details,
  .detailsection {
    text-align: left;
    display: flex;
  }
  .back {
    margin-right: 65px;
  }

  .back-icon {
    margin-top: 11.98px;
    cursor: pointer;
  }

  .profile-div {
    border-radius: 12.25px;
    padding: 7px;
    background: var(--borderred);
    width: 154px;
    height: 154px;
  }

  .profile-img {
    border-radius: 10.5px;
    height: 100%;
    object-fit: cover;
    width: 100%;
    background-color: var(--white);
  }

  .creators-name {
    font-weight: 500;
    font-size: 46.9718px;
    line-height: 58px;
    letter-spacing: 1.02042px;
    color: var(--textdark);
    word-break: break-word;
  }

  .light-text {
    font-weight: 400;
    font-size: 25.9155px;
    line-height: 41px;
    letter-spacing: 0.259155px;
    color: var(--textlight);
    margin-top: 4px;
  }
  p.votecount {
    font-weight: 600;
    font-size: 20px;

    letter-spacing: 0.259155px;
    color: var(--textlight);
  }
  .twitter {
    cursor: pointer;
  }

  .vertical {
    cursor: pointer;
    margin-top: 21.8px;
  }

  .threedot {
    background-color: transparent;
    padding: 0px;
    border: none;
    outline: none;
  }

  .threedot:hover {
    background-color: transparent;
  }

  .menu {
    border-radius: 4px;
    background-color: var(--white);
    border: 1px solid var(--border);
    min-width: 118px;
  }

  .threedot.dropdown-toggle::after {
    display: none;
  }

  .dropdown.show {
    display: inline-block;
  }

  .dropdown-item {
    font-size: 14px;
    font-weight: 400;
    color: var(--textlight);
    padding-left: 23px;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    text-decoration: none;
    cursor: pointer;
  }

  .dropdown-item:active,
  .dropdown-item:hover {
    background-color: var(--extralight);
  }

  .btn-check:active + .btn-primary:focus,
  .btn-check:checked + .btn-primary:focus,
  .btn-primary.active:focus,
  .btn-primary:active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  button:focus:not(:focus-visible) {
    box-shadow: none;
  }

  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border: none;
  }

  .right {
    padding-left: 50px;
  }

  .top-section {
    padding-bottom: 53px;
  }

  @media screen and (max-width: 768px) {
    .back {
      margin-right: 30px;
    }

    .right {
      padding-left: 0;
      padding-top: 10px;
    }

    .details {
      flex-direction: column;
    }

    .creators-name {
      font-size: 36px;
      line-height: 40px;
    }
  }

  @media screen and (max-width: 576px) {
    .profile-div {
      width: 130px;
      height: 130px;
    }

    .back {
      margin-right: 15px;
    }

    .creators-name {
      font-size: 32px;
      line-height: 38px;
    }

    .light-text {
      font-size: 24px;
      line-height: 38px;
      word-break: break-word;
    }
  }

  @media screen and (max-width: 440px) {
    .profile-div {
      width: 100px;
      height: 100px;
    }

    .light-text {
      font-size: 21px;
      line-height: 25px;
    }
  }
}
