.NavBar {
    margin-bottom: 85px;

    .navbar-collapse {
        flex-grow: 0;
    }

    .navbar-expand-lg {
        justify-content: space-between;
    }

    .navbar {
        padding-left: 3%;
        padding-right: 3%;
        height: 85px;
        transition: top 0.5s ease 0s;
        position: fixed;
        background: var(--white);
        width: 100%;
        top: 0;
        z-index: 1050;
        box-shadow: 0px 11px 25px 9px rgba(0, 0, 0, 0.1);
    }

    .Navbar-brand {
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        font-size: 20px;
        font-weight: 500;
        height: 100%;
        padding: 8px 0px;
        position: relative;
    }

    .b-logo {
        height: 54px;
        width: 54px;
        cursor: pointer;
    }

    .p-name {
        margin-top: 27px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        hr {
            height: 3px;
            border-radius: 2px 2px 0 0;
            background: var(--underline);
            opacity: 1;
            border: 0;
            width: 160px;
            margin: auto;
        }
    }

    .CustomButton {
        .walletcustombutton {
            color: var(--white);
            border: 1px solid linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%);
            width: 146.14px;
            height: 46.26px;
            border-radius: 10.5134px;
            font-size: 19px;
            font-weight: 600;

        }

        .walletnewcustombutton {
            color: var(--white);
            border: 1px solid linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%);
            width: 146.14px;
            height: 46.26px;
            border-radius: 5px;
            font-size: 19px;
            font-weight: 600;
            margin-left: 10px;
        }
    }

    .Nav-Center {
        display: flex;
        width: 100%;
    }


    .navbar-toggler {
        border: none;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }


    .nav-logo {
        padding: 0 10px;
    }

    .wallet-logo {
        min-width: 37px;
        height: 28px;
        cursor: pointer;
        position: relative;
        top: 12px;
    }

    .left-nav {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 40%;
    }

    .right-nav {
        display: flex;
        align-items: center;
    }

    .nav-text {
        font-weight: 500;
        font-size: 20px;
        color: var(--textdark);
        margin-bottom: 25px;
    }

    .AccountAdress {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: var(--textlight);
        margin-right: 14px;
        width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
        white-space: nowrap;
    }

    .ellipse {
        box-shadow: 1.44444px 1.44444px 7.22222px rgba(0, 0, 0, 0.1);
        width: 52px;
        height: 52px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
    }

    .profile {
        .profile-circle {
            height: 47.67px;
            width: 47.67px;
            position: relative;
            align-items: center;
            justify-content: center;
            display: flex;
            box-sizing: border-box;
            border-radius: 100px;
        }

        .profile-img {
            width: 47.67px;
            height: 47.67px;
            border-radius: 50%;
            object-fit: cover;
            background-color: var(--white);
            position: relative;
            top: 2.2px;
            right: -2.1px
        }
    }

    .notify-count {
        width: 25px;
        height: 25px;
        line-height: 21px;
        font-size: 11px;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        right: 0px;
        color: var(--white);
        background: linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%);
        padding: 0px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .notify-count-null {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        position: absolute;
        top: 10.5px;
        right: 10.5px;
        background: linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%);
        cursor: pointer;
    }

    .dropdown-toggle::after {
        display: none;
        margin-left: none;
        vertical-align: none;
        content: "";
        border-top: none;
        border-right: none;
        border-bottom: 0;
        border-left: none;
    }

    .dropdown>a {
        display: flex;
        align-items: center;
    }

    .dropdown {
        position: relative;
        transition: 1s ease;

        *::-webkit-scrollbar {
            display: none;
        }

        &:hover {
            .dropdown-nav {
                opacity: 1;
                z-index: 10050;
                box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                max-height: calc(100vh - 100px);
            }
        }
    }

    .dropdown-nav {
        transition: .3s;
        max-height: 0px;
        overflow-y: auto;
        box-shadow: 0px 8.35519px 18.9891px -3.03825px rgba(0, 0, 0, 0.1);
        background-color: var(--white);
        color: var(--textdark);
        width: max-content;
        list-style: none;
        opacity: 0;
        z-index: -5;
        position: absolute;
        top: 113%;
        left: -128px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        a {
            text-decoration: none;

            .dp-text {
                border: 1.5px solid transparent;
            }

            &:hover {
                .dp-text {
                    color: var(--textdark);
                    border-bottom: 1.5px solid var(--underline);
                    cursor: pointer;
                }
            }
        }
    }

    .ul-class {
        border: none;
        border-radius: 0px 0px 4.55738px 4.55738px;
        margin: 0px;
        padding: 5px 40px;
        z-index: 10050;
    }

    .a-class {
        width: 100%;
        font-weight: 600;
        border: none;
        text-align: left;

    }

    .dp-text {
        font-weight: 500;
        font-size: 18.2295px;
        color: var(--textlight);
        white-space: nowrap;
        padding: 15.95px 0px 11.45px 0px;
        cursor: pointer;
    }

    .search-box {
        width: 65%;
    }

    .wallet-section {
        position: relative;

        .walletmenu {
            position: absolute;
            top: 85px;
            left: -390px;
        }
    }

    @media screen and (max-width:991px) {

        .hide-for-mobile {
            display: none;
        }

        .b-logo {
            margin-left: 15px;
        }

        .left-nav {
            justify-content: left;
            width: max-content;
        }
    }

    @media screen and (min-width:992px) {
        .hide-for-desktop {
            display: none;
        }
    }


    @media screen and (max-width:640px) {
        .nav-text {
            font-size: 17px;
        }

        .p-name {
            margin-top: 32px;
        }
    }


    @media screen and (max-width:576px) {
        .p-name {
            display: none;
        }

        .CustomButton .walletnewcustombutton {
            border-radius: 9px;
            font-size: 17px;
            font-weight: 600;
            width: 117.86px;
            height: 37.31px;
        }

        .right-nav .walletmenu {
            left: -333px;
        }
    }


}