.Filter {
    .checkboxholder {
        label {
            font-weight: 500;
            font-size: 18.23px;
            line-height: 22px;
            color: var(--textlight);
            margin-left: 10px;
            text-align: left;
            margin-top: 4px;
        }
    }

    .filter-type {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--textlight);
        margin-bottom: 15px;
    }

    .form-check {
        margin-bottom: 20px;
        display: flex;
    }

    .form-check-input:checked {
        background-color: var(--inputred);
        border: 2px solid var(--white);
        width: 20px;
        height: 20px;
    }

    .form-check-input {
        box-shadow: -5px 5px 10px rgb(0 0 0 / 10%);
        background-color: var(--extralight);
        border: 2px solid var(--white);
        min-width: 20px;
        height: 20px;
    }

    .form-check-input:checked[type=radio] {
        background-image: none
    }

    .btn-div {
        display: flex;
        justify-content: center;
        margin-top: 4px;
      }
    
      .confirm {
        .buttonmain {
          margin-right: 21.14px;
        }
      }
    
      .cancel {
        .cancelbtn{
          background: linear-gradient(#fff 0 0) padding-box, linear-gradient(86.04deg, #2AC3D1 -12.8%, #3F51DC 100%) border-box;
          color: var(--btntext);
        }
        .buttonmain:hover {
          box-shadow: none;
        }
        .cancelbtn:hover {
          box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
        }
      }
    
}